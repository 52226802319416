import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const CustomizeStatuses = () => {
  return (
    <LayoutComponent>
      <h3>Customize Statuses</h3>
      <ol>
        <li>Click an option in the <strong>Statuses</strong> section.</li>
        <li>Enter a status name. You can also enter a description, though it isn't required.</li>
        <li>Select a color to represent the status (optional but recommended).</li>
        <li>Click <strong>Add</strong>. Your status appears on the list.</li>
      </ol>

      <h5>Default Statuses</h5>
      <p>There will be different default statuses in GAINSS for test cases, test cycles, executions, etc.</p>
    </LayoutComponent>
  )
}

export default CustomizeStatuses
